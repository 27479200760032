.scanner-switch {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 50%;
}

/* 
@media (max-width: 900px) {
    .scanner-switch {        
        top: 96px;
    }    
}

@media (min-width: 1024px) {
    .scanner-switch {        
        top: 103px;
        right: 18%;
    }    
}

@media (min-width: 1300px) {
    .scanner-switch {        
        top: 115px;
        right: 18%;
    }    
} */


.component-barcode-scanner {
    width: 100%;
    height: 100%;
    min-width: 640px;
    min-height: 300px;
    resize: both;
}

.dce-bg-loading {
    animation: 1s linear infinite dce-rotate;
    width: 25%;
    height: 25%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    fill: #aaa;
}

.dce-bg-camera {
    width: 40%;
    height: 40%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    fill: #aaa;
}

.dce-video-container {
    width: 100%;
    height: 100%;
}

.dce-scanarea {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 15px;
    top: 0;
}

.dce-scanlight {
    width: 100%;
    height: 3%;
    position: absolute;
    animation: 3s infinite dce-scanlight;
    border-radius: 50%;
    box-shadow: 0px 0px 2vw 1px #00e5ff;
    background: #fff;
}

.div-select-container {
    position: absolute;
    left: 0;
    top: 0;
}

.dce-sel-camera {
    display: block;
}

.dce-sel-resolution {
    display: block;
    margin-top: 5px;
}

@keyframes dce-rotate {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

@keyframes dce-scanlight {
    from {
        top: 0;
    }

    to {
        top: 97%;
    }
}